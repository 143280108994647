
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import {
  personOutline,
  schoolOutline,
  locationOutline,
  briefcaseOutline,
  homeOutline,
  chevronBackOutline,
} from "ionicons/icons";
import router from "@/router";
import { ref } from "vue";

import {user} from "@/auth";
import {
  loadProfileByUid,
} from "@/components/profile/show";

import ProfileHeder from "@/components/profile/show/ProfileHeader.vue"
import ProfileBody from "@/components/profile/show/ProfileBodyC.vue";
import ProfileSkeleton from "@/components/profile/show/ProfileSkeleton.vue";

export default {
  name: "MyProfile",
  components: {
    ProfileBody,
    ProfileHeder,
    ProfileSkeleton,
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonIcon,
  },
  setup() {
    // TODO
    // const allowedUsers = ["3IzTgLkdV7ZwuKU4bPfEgrsTHGp2", "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2", "k4rbNyfy5qWB2fAz24iAQfyxaiH3"];
    // if ( !allowedUsers.includes(user.value.uid)) {
    //   router.replace("/laska");
    // }

    
    
    const profile = ref<object|null>({});
    const photos = ref<string[]>([]);
    const loadingMyProfile = ref(true);
    const errorOccured = ref(false);
    
    const loadMyProfileToShow = async () => {
      loadingMyProfile.value = true;
      try {
        const result = await loadProfileByUid(user.value.uid);
        if(result != null) {
          profile.value = result.profile;
          photos.value = result.allPhotos;
        } else {
          profile.value = null;
        }
      } catch (err) {
        errorOccured.value = true;
      } finally {
        loadingMyProfile.value = false;
      }
      
    }
    loadMyProfileToShow();

    const goEdit = () => {
      router.replace("/profile-edit");
    };

    const goBack = () => {
      router.push("/my-account");
    };

    return {
      // REFERENCES
      // myProfile,
      loadingMyProfile,
      errorOccured,
      photos,
      profile,

      // FUNCTIONS
      goEdit,
      goBack,

      // ICONS
      personOutline,
      schoolOutline,
      locationOutline,
      briefcaseOutline,
      homeOutline,
      chevronBackOutline,
    };
  },
};
