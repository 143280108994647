<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="primary" @click="goBack()">
            <ion-icon :icon="chevronBackOutline"></ion-icon>
            {{$t("buttons.back")}}
          </ion-button>
        </ion-buttons>
        <ion-title>{{$t("profile.myProfile")}}</ion-title>
        <ion-buttons slot="end" >
          <ion-button color="primary" @click="goEdit()">{{$t("buttons.edit")}}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content text-center class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{$t("profile.myProfile")}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div v-if="loadingMyProfile">
        <ProfileSkeleton />
      </div>
      <div v-else>
        <div v-if="profile == null || errorOccured">
          {{$t("errors.problemOccured")}}
        </div>
        <div v-else>
          <ProfileHeder :profile="profile" :allPhotos="photos" />
          <ProfileBody :profile="profile" :allPhotos="photos"/>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import {
  personOutline,
  schoolOutline,
  locationOutline,
  briefcaseOutline,
  homeOutline,
  chevronBackOutline,
} from "ionicons/icons";
import router from "@/router";
import { ref } from "vue";

import {user} from "@/auth";
import {
  loadProfileByUid,
} from "@/components/profile/show";

import ProfileHeder from "@/components/profile/show/ProfileHeader.vue"
import ProfileBody from "@/components/profile/show/ProfileBodyC.vue";
import ProfileSkeleton from "@/components/profile/show/ProfileSkeleton.vue";

export default {
  name: "MyProfile",
  components: {
    ProfileBody,
    ProfileHeder,
    ProfileSkeleton,
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonIcon,
  },
  setup() {
    // TODO
    // const allowedUsers = ["3IzTgLkdV7ZwuKU4bPfEgrsTHGp2", "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2", "k4rbNyfy5qWB2fAz24iAQfyxaiH3"];
    // if ( !allowedUsers.includes(user.value.uid)) {
    //   router.replace("/laska");
    // }

    
    
    const profile = ref<object|null>({});
    const photos = ref<string[]>([]);
    const loadingMyProfile = ref(true);
    const errorOccured = ref(false);
    
    const loadMyProfileToShow = async () => {
      loadingMyProfile.value = true;
      try {
        const result = await loadProfileByUid(user.value.uid);
        if(result != null) {
          profile.value = result.profile;
          photos.value = result.allPhotos;
        } else {
          profile.value = null;
        }
      } catch (err) {
        errorOccured.value = true;
      } finally {
        loadingMyProfile.value = false;
      }
      
    }
    loadMyProfileToShow();

    const goEdit = () => {
      router.replace("/profile-edit");
    };

    const goBack = () => {
      router.push("/my-account");
    };

    return {
      // REFERENCES
      // myProfile,
      loadingMyProfile,
      errorOccured,
      photos,
      profile,

      // FUNCTIONS
      goEdit,
      goBack,

      // ICONS
      personOutline,
      schoolOutline,
      locationOutline,
      briefcaseOutline,
      homeOutline,
      chevronBackOutline,
    };
  },
};
</script>


<style scoped>
#titlePic::part(image) {
  border-radius: 30px;
  height: 180px;
  object-fit: cover;
  max-width: 700px;
  margin: auto;
}

.accountTabHeader {
  padding: 10px;
  margin-bottom: 30px;
  text-align: center;
}

.mainInfoCard {
  margin: 10px;
  padding: 10px;
  -webkit-box-shadow: 0 0 5px 2px #ddd;
  -moz-box-shadow: 0 0 5px 2px #ddd;
  box-shadow: 0 0 5px 2px #ddd;
  border-radius: 20px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 5vw);
  grid-gap: 15px;
}
</style>